import React, { useState } from 'react';
import Header from '../components/Header';
import axios from 'axios';

const Cadastrar_esp: React.FC = () => {
  
  const [macAddress, setMacAddress] = useState('');
  const [macError, setMacError] = useState('');
  const [macSucess, setMacSucess] = useState('');




  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    if (!macRegex.test(macAddress)) {
      setMacError('Formato de MAC address inválido');
      return;
    } else {
      setMacError('');
    }

    try {
      //await axios.post('http://localhost:3001/macs/register', { mac: macAddress });
      await axios.post('https://faceclock.eastus.cloudapp.azure.com:3001/apimacs/register', { mac: macAddress });
      setMacSucess('Registro bem-sucedido');
    } catch (error) {
      setMacError('Erro ao fazer registro. Tente novamente.');
    }

  };


  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">

        <div className="max-w-4xl mx-auto bg-neutral-900 rounded-lg shadow-lg">

          <div className="p-6">
          <div className="flex items-center justify-center py-4">
            {macSucess && (
              <div className="bg-green-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
                {macSucess}
              </div>
            )}

            {macError && (
                <div className="bg-red-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                  <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  {macError}
                </div>
              )}
          </div>

            <h2 className="text-2xl font-bold mt-6 mb-4">Cadastrar novo ESP32</h2>
            <form onSubmit={handleSubmit} className="space-y-4">

              <div>
                <label className="block text-sm font-medium text-gray-300">MAC Address (ESP32)</label>
                <input
                  type="text"
                  className={`mt-1 block w-full bg-neutral-800 border ${macError ? 'border-red-600' : 'border-neutral-700'} rounded-lg p-2.5 text-gray-100`}
                  value={macAddress}
                  onChange={(e) => setMacAddress(e.target.value)}
                  placeholder="00:00:00:00:00:00"
                  required
                />
              </div>

              <button
                type="submit"
                className="w-full bg-custom-blue text-white rounded-lg p-2.5 mt-4 font-medium"
              >
                Cadastrar
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Cadastrar_esp;
