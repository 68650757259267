import React, { useState } from 'react';
import Header from '../components/Header';

const Cadastrar_maquina: React.FC = () => {
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [tipo, setTipo] = useState('');
  const [macAddress, setMacAddress] = useState('');
  const [macError, setMacError] = useState('');




  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const macRegex = /^([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})$/;
    if (!macRegex.test(macAddress)) {
      setMacError('Formato de MAC address inválido');
      return;
    } else {
      setMacError('');
    }




  };





  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">
        <div className="max-w-4xl mx-auto bg-neutral-900 rounded-lg shadow-lg">
          <div className="p-6">

            <h2 className="text-2xl font-bold mt-6 mb-4">Cadastrar nova máquina</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-300">Nome da Máquina</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">Descrição</label>
                <textarea
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={descricao}
                  onChange={(e) => setDescricao(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">Tipo</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">Cliente</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">Setor</label>
                <input
                  type="text"
                  className="mt-1 block w-full bg-neutral-800 border border-neutral-700 rounded-lg p-2.5 text-gray-100"
                  value={tipo}
                  onChange={(e) => setTipo(e.target.value)}
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300">MAC Address (ESP32)</label>
                <input
                  type="text"
                  className={`mt-1 block w-full bg-neutral-800 border ${macError ? 'border-red-600' : 'border-neutral-700'} rounded-lg p-2.5 text-gray-100`}
                  value={macAddress}
                  onChange={(e) => setMacAddress(e.target.value)}
                  placeholder="00:00:00:00:00:00"
                  required
                />
                {macError && <p className="text-red-600 text-sm mt-1">{macError}</p>}
              </div>

              <button
                type="submit"
                className="w-full bg-custom-blue text-white rounded-lg p-2.5 mt-4 font-medium"
              >
                Cadastrar
              </button>
            </form>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Cadastrar_maquina;
