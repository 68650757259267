import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';

const Login: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { login } = useAuth();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://faceclock.eastus.cloudapp.azure.com:3001/auth/login', { username: email, password });
      //const response = await axios.post('http://localhost:3001/auth/login', { username: email, password });
      if (response.data.token) {
        const token = response.data.token;
        localStorage.setItem('token', token);
        login();
        console.log('Login efetuado com sucesso!');
        console.log('Token armazenado:', token);
        navigate('/broker');
      } else {
        setError('Erro ao fazer login. Tente novamente.');
      }
    } catch (error) {
      setError('Erro ao fazer login. Tente novamente.');
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-black">
      <img src="/logo.png" alt="Logo" className="w-1/4 mb-8" />
      <div className="max-w-md px-6 py-8 bg-white shadow-md rounded-lg text-center">
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-custom-blue"
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-custom-blue"
            />
          </div>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full px-4 py-2 bg-custom-blue text-white rounded-lg font-medium transition duration-500"
          >
            Entrar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;
