import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import '../styles/App.css';

const Header: React.FC = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (
        <header className="bg-neutral-900 text-white py-4 px-6 border-b border-white-600">
            <div className="container mx-auto flex items-center justify-between">
                {/* Logo alinhado à esquerda e responsivo */}
                <h1 className="text-2xl font-bold flex-shrink-0">
                    <img 
                        src="/logo.png" 
                        alt="Logo" 
                        className="w-24 md:w-32 lg:w-40 xl:w-48" 
                    />
                </h1>
                
                {/* Menu para telas menores */}
                <div className="flex items-center md:hidden">
                    <button onClick={toggleMenu} className="text-white focus:outline-none">
                        <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
                        </svg>
                    </button>
                </div>

                {/* Navegação para telas maiores */}
                <nav className="hidden md:flex space-x-4 flex-shrink-0">
                    <Link 
                        to="/broker" 
                        className="text-sm md:text-base lg:text-lg hover:text-gray-400"
                    >
                        Home
                    </Link>
                    <Link 
                        to="/about" 
                        className="text-sm md:text-base lg:text-lg hover:text-gray-400"
                    >
                        Usuários
                    </Link>
                    <Link 
                        to="/maquinas" 
                        className="text-sm md:text-base lg:text-lg hover:text-gray-400"
                    >
                        Máquinas
                    </Link>
                    <Link 
                        to="/esp32" 
                        className="text-sm md:text-base lg:text-lg hover:text-gray-400"
                    >
                        ESP32
                    </Link>
                    <Link 
                        to="/topicos" 
                        className="text-sm md:text-base lg:text-lg hover:text-gray-400"
                    >
                        Tópicos MQTT
                    </Link>
                    <button
                        onClick={handleLogout}
                        className="text-xs lg:text-base text-white rounded "
                    >
                        <img src='/logout.png' alt="Logout" className="w-8 h-8 inline-block mr-1" />
                    </button>
                </nav>
            </div>

            {/* Menu dropdown para telas menores */}
            {isOpen && (
                <div className="md:hidden">
                    <nav className="flex flex-col space-y-2 px-6 pb-4">
                        <Link 
                            to="/" 
                            className="text-sm hover:text-gray-400"
                            onClick={() => setIsOpen(false)}
                        >
                            Home
                        </Link>
                        <Link 
                            to="/about" 
                            className="text-sm hover:text-gray-400"
                            onClick={() => setIsOpen(false)}
                        >
                            Usuários
                        </Link>
                        <Link 
                        to="/maquinas" 
                        className="text-sm md:text-base lg:text-lg hover:text-gray-400"
                        >
                            Máquinas
                        </Link>
                        <Link 
                        to="/esp32" 
                        className="text-sm md:text-base lg:text-lg hover:text-gray-400"
                    >
                        ESP32
                    </Link>
                    <Link 
                        to="/topicos" 
                        className="text-sm md:text-base lg:text-lg hover:text-gray-400"
                    >
                        Tópicos MQTT
                    </Link>
                    <button
                        onClick={handleLogout}
                        className="text-xs lg:text-base text-white rounded "
                    >
                        <img src='/logout.png' alt="Logout" className="w-8 h-8 inline-block mr-1" />
                    </button>
                    </nav>
                </div>
            )}
        </header>
    );
};

export default Header;
