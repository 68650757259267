import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import { Link } from 'react-router-dom';

const Topicos: React.FC = () => {
  const [machines, setMachines] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Função para buscar máquinas do backend
  const fetchMachines = async (page: number) => {
    try {
      //const response = await axios.get(`http://localhost:3001/api/machines?page=${page}&limit=5`);
      const response = await axios.get(`https://faceclock.eastus.cloudapp.azure.com:3001/api/machines?page=${page}&limit=5`);
      setMachines(response.data.machines);
      setTotalPages(Math.ceil(response.data.total / 5)); // Atualiza total de páginas com base no total de máquinas
    } catch (error) {
      console.error('Erro ao buscar máquinas:', error);
    }
  };

  useEffect(() => {
    fetchMachines(currentPage);
  }, [currentPage]);

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">
        <div className="max-w-full mx-auto bg-neutral-900 rounded-lg shadow-lg">
          <div className="p-6 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">Tópicos MQTT cadastrados</h1>
            <div className="flex gap-x-4">
              <Link
                to="/cadastro_maquina"
                className="text-sm md:text-base lg:text-lg bg-custom-blue text-white hover:bg-blue-700 hover:text-gray-200 py-2 px-4 rounded-lg"
              >
                + Tópico MQTT
              </Link>


            </div>

          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-neutral-700">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">ID</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Tópico</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Máquina</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Cliente</th>

                </tr>
              </thead>
              <tbody className="divide-y divide-neutral-700">
                {machines.length > 0 ? (
                  machines.map((machine) => (
                    <tr key={machine.id}>
                      <td className="px-4 py-2 text-sm text-gray-100">{machine.name}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{machine.desc}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{machine.type}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">cliente x</td>
  
                      <td className="px-4 py-2 text-sm text-gray-100">
                        <button className="bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600">Editar</button>
                        <button className="bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600 ml-2">Desativar</button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="px-4 py-2 text-sm text-gray-100 text-center">Nenhuma máquina encontrada</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between mt-4">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="bg-gray-600 text-white px-4 py-2 rounded"
            >
              Anterior
            </button>
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage >= totalPages}
              className="bg-gray-600 text-white px-4 py-2 rounded"
            >
              Próximo
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Topicos;