import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import { Link } from 'react-router-dom';

const Esp32: React.FC = () => {
  const [macs, setMacs] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [macError, setMacError] = useState('');
  const [macSucess, setMacSucess] = useState('');
  // Função para buscar máquinas do backend
  const fetchMacs = async (page: number) => {
    try {
      //const response = await axios.get(`http://localhost:3001/apimacs/macs?page=${page}&limit=5`);
      const response = await axios.get(`https://faceclock.eastus.cloudapp.azure.com:3001/apimacs/macs?page=${page}&limit=5`);
      setMacs(response.data.macs);
      setTotalPages(Math.ceil(response.data.total / 5)); // Atualiza total de páginas com base no total de máquinas
    } catch (error) {
      console.error('Erro ao buscar macs:', error);
    }
  };

  // Função para desativar MAC
  const handleDelete = async (id: number) => {
    try {
      //await axios.post(`http://localhost:3001/apimacs/delete/${id}`);
      await axios.post(`https://faceclock.eastus.cloudapp.azure.com:3001/apimacs/delete/${id}`);
      fetchMacs(currentPage);
      setMacSucess('MAC desativado com sucesso');
    } catch (error) {
      console.error('Erro ao desativar MAC:', error);
      setMacError('Erro ao desativar MAC. Tente novamente.');
    }
  };

  // Função para ativar MAC
  const handleActivate = async (id: number) => {
    try {
      //await axios.post(`http://localhost:3001/apimacs/activate/${id}`);
      await axios.post(`https://faceclock.eastus.cloudapp.azure.com:3001/apimacs/activate/${id}`);
      fetchMacs(currentPage);
      setMacSucess('MAC ativado com sucesso');
    } catch (error) {
      console.error('Erro ao ativar MAC:', error);
      setMacError('Erro ao ativar MAC. Tente novamente.');
    }
  };




  useEffect(() => {
    fetchMacs(currentPage);
  }, [currentPage]);

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header />
      <main className="flex-1 p-6">
      <div className="flex items-center justify-center py-4">
            {macSucess && (
              <div className="bg-green-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
                </svg>
                {macSucess}
              </div>
            )}

            {macError && (
                <div className="bg-red-500 text-white text-lg font-semibold rounded-md shadow-lg p-4 flex items-center">
                  <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                  {macError}
                </div>
              )}
          </div>
        <div className="max-w-full mx-auto bg-neutral-900 rounded-lg shadow-lg">
          <div className="p-6 flex items-center justify-between">
            <h1 className="text-2xl font-bold mb-4">Microcrontroladores cadastrados</h1>
            <div className="flex gap-x-4">
              <Link
                to="/cadastro_esp32"
                className="text-sm md:text-base lg:text-lg bg-custom-blue text-white hover:bg-blue-700 hover:text-gray-200 py-2 px-4 rounded-lg"
              >
                + ESP32
              </Link>


            </div>

          </div>

          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-neutral-700">
              <thead>
                <tr>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">ID</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">MAC</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Máquina</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Cliente</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Status</th>
                  <th className="px-4 py-2 text-left text-sm font-medium text-gray-300">Ações</th>

                </tr>
              </thead>
              <tbody className="divide-y divide-neutral-700">
                {macs.length > 0 ? (
                  macs.map((macs) => (
                    <tr key={macs.id}>
                      <td className="px-4 py-2 text-sm text-gray-100">{macs.id}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{macs.mac}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{macs.type}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">cliente x</td>
                      <td className="px-4 py-2 text-sm text-gray-100">{macs.deleted_at ? "Inativo" : "Ativo"}</td>
                      <td className="px-4 py-2 text-sm text-gray-100">
                        <button className="bg-blue-500 text-white px-3 py-1 rounded-lg hover:bg-blue-600">Editar</button>
                        {macs.deleted_at ? (
                          <button className="bg-green-500 text-white px-3 py-1 rounded-lg hover:bg-green-600 ml-2" onClick={() => handleActivate(macs.id)}>Ativar</button>
                        ) : (
                          <button className="bg-red-500 text-white px-3 py-1 rounded-lg hover:bg-red-600 ml-2" onClick={() => handleDelete(macs.id)} >Desativar</button>
                        )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5} className="px-4 py-2 text-sm text-gray-100 text-center">Nenhum MAC encontrado</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          <div className="flex justify-between mt-4">
            <button
              onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
              className="bg-gray-600 text-white px-4 py-2 rounded"
            >
              Anterior
            </button>
            <button
              onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
              disabled={currentPage >= totalPages}
              className="bg-gray-600 text-white px-4 py-2 rounded"
            >
              Próximo
            </button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Esp32;