import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';

const MQTTComponent = ({ onMessageReceived }) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const brokerUrl = 'wss://5a6621c529404b309ef05ecfccc94eba.s1.eu.hivemq.cloud:8884/mqtt'; // URL do broker MQTT usando WebSocket seguro
    const username = 'hivemq.webclient.1723511228836'; // Usuário do broker MQTT
    const password = 'U0ar6Ht%gQ7ER@8;w.Al'; // Senha do broker MQTT

    const mqttClient = mqtt.connect(brokerUrl, {
      username,
      password,
      protocol: 'wss', // Protocolo WebSocket seguro
    });

    mqttClient.on('connect', () => {
      console.log('Conectado ao broker MQTT');
      setConnected(true);

      mqttClient.subscribe('test/topic', (err) => {
        if (err) {
          console.error('Falha ao se inscrever no tópico:', err);
        } else {
          console.log('Inscrito no tópico: test/topic');
        }
      });
    });

    mqttClient.on('message', (topic, message) => {
      console.log(`Mensagem recebida no tópico ${topic}: ${message.toString()}`);
      onMessageReceived(message.toString());
    });

    mqttClient.on('error', (error) => {
      console.error('Erro de conexão MQTT:', error);
    });

    return () => {
      mqttClient.end();
    };
  }, [onMessageReceived]);

  return (
<div className="p-4 rounded-lg">
  <h1 className="text-xl font-semibold mb-2">Mensagens MQTT</h1>
  <p className="flex items-center">
    Status da conexão:
    <div
      className={`${
        connected ? 'bg-green-500' : 'bg-red-500'
      } rounded-lg max-w-28 text-center ml-2 px-2 text-white`}
    >
      {connected ? 'Conectado' : 'Desconectado'}
    </div>
  </p>
</div>


  );
};

export default MQTTComponent;
