import React, { createContext, useContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

interface AuthContextType {
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log('AuthProvider token aqui', token); // Adicionado para depuração
    console.log('testeeee', isAuthenticated); 

    if (token) {
      try {
        // Verifica se o token ainda é válido
        const decoded: any = jwtDecode(token); // Usa a função correta
        const currentTime = Date.now() / 1000; // Tempo atual em segundos

        if (decoded.exp && decoded.exp > currentTime) {
          setIsAuthenticated(true);
          console.log('ok, token válido');
        } else {
          setIsAuthenticated(false);
          console.log('ops');

          localStorage.removeItem('token');
        }
      } catch (error) {
        console.error('Token inválido:', error);
        setIsAuthenticated(false);
        localStorage.removeItem('token');
      }
    } else {
      setIsAuthenticated(false);
      console.log('sem token');
    }
  }, [isAuthenticated]);

  const login = () => setIsAuthenticated(true);
  const logout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    console.log('logout');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth deve ser usado dentro de um AuthProvider');
  }
  return context;
};
