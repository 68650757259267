import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Broker from './pages/Broker';
import ProtectedRoute from './auth/ProtectedRoute';
import CadastrarMaquina from './pages/CadastrarMaquina';
import Maquinas from './pages/Maquinas';
import Esp32 from './pages/Esp32';
import CadastrarEsp from './pages/CadastrarEsp';
import Topicos from './pages/Topicos';

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/broker" element={<ProtectedRoute element={<Broker />} />} />
        <Route path="/maquinas" element={<ProtectedRoute element={<Maquinas />} />} />
        <Route path="/cadastro_maquina" element={<ProtectedRoute element={<CadastrarMaquina />} />} />
        <Route path="/esp32" element={<ProtectedRoute element={<Esp32 />} />} />
        <Route path="/cadastro_esp32" element={<ProtectedRoute element={<CadastrarEsp />} />} />
        <Route path="/topicos" element={<ProtectedRoute element={<Topicos />} />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;
