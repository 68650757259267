import React, { useState } from 'react';
import MQTTComponent from '../components/MQTTComponent'; // Ajuste o caminho conforme necessário
import Header from '../components/Header';

const Broker: React.FC = () => {
  const [alerts, setAlerts] = useState<string[]>([]);

  // Função para adicionar alertas
  const addAlert = (message: string) => {
    setAlerts((prevAlerts) => [message, ...prevAlerts].slice(0, 10)); // Mantém apenas os 10 alertas mais recentes
  };

  return (
    <div className="flex flex-col min-h-screen bg-black text-gray-100">
      <Header /> {/* Inclua o Header aqui */}
      <main className="flex-1 p-6">
        <div className="max-w-6xl mx-auto bg-neutral-900 rounded-lg shadow-lg">
          <div className="p-6">
            <h1 className="text-2xl font-bold mb-4">Dashboard de Alertas</h1>
            <div className="space-y-4">
              <MQTTComponent onMessageReceived={addAlert} /> {/* Passa a função de adicionar alertas para o MQTTComponent */}

              <div className="flex justify-between items-center">
                <h2 className="text-xl font-semibold mb-2">Alertas Recentes</h2>
                <button className="text-white bg-red-500 px-4 py-2 rounded-lg" onClick={() => setAlerts([])}>
                  Limpar Alertas
                </button>
              </div>

        
                <div className="space-y-2">
                  {alerts.length > 0 ? (
                    alerts.map((alert, index) => {
                      // Extrai a temperatura da mensagem
                      const temperatureMatch = alert.match(/Temperatura: (\d+(\.\d+)?)°C/);
                      const temperature = temperatureMatch ? parseFloat(temperatureMatch[1]) : null;

                      // Determina a classe de fundo com base na temperatura
                      const isHighTemperature = temperature !== null && temperature > 30;

                      return (
                        <div
                          key={index}
                          className={`p-3 rounded-lg shadow-md ${
                            isHighTemperature ? 'bg-red-600' : 'bg-gray-800'
                          } text-white`}
                        >
                          {alert}
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-gray-400">Nenhum alerta recebido ainda.</p>
                  )}
                </div>


            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Broker;
