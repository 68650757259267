import React, { useState } from 'react';
import axios from 'axios';

const Register: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError('As senhas não coincidem.');
      return;
    }

    try {
      await axios.post('https://127.0.0.1:3001/auth/register', { username: email, password });
      alert('Registro bem-sucedido');
    } catch (error) {
      setError('Erro ao fazer registro. Tente novamente.');
    }
  };

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-black">

      {/* Logo da empresa */}
      <img src="/logo.png" alt="Logo" className="w-1/4 mb-8" />
      
      <div className="max-w-md px-6 py-8 bg-white shadow-md rounded-lg text-center">
        <h2 className="text-2xl font-bold mb-4">Registrar teste</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <input
              type="text"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-custom-blue"
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              placeholder="Senha"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-custom-blue"
            />
          </div>
          <div className="mb-4">
            <input
              type="password"
              placeholder="Confirmar Senha"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-custom-blue"
            />
          </div>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <button
            type="submit"
            className="w-full px-4 py-2 bg-custom-blue text-white rounded-lg font-medium transition duration-500"
          >
            Registrar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
